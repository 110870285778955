<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="12" md="3" lg="3" sm="6">
            <v-alert text color="primary" outlined height="120">
              <v-row>
                <v-col class="mt-3">
                  <h1 class="text-center">{{ widget.item_count }}</h1>
                  <p class="text-center">سەرجەم کاڵاکان</p>
                </v-col>
                <v-col class="text-left"><v-icon size="100" color="primary">mdi-tag</v-icon></v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" md="3" lg="3" sm="6">
            <v-alert text color="warning" outlined height="120">
              <v-row>
                <v-col class="mt-3">
                  <h1 class="text-center">{{ widget.category_count }}</h1>
                  <p class="text-center">سەرجەم جۆرەکان</p>
                </v-col>
                <v-col class="text-left"><v-icon size="100" color="warning">mdi-shape</v-icon></v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" md="3" lg="3" sm="6">
            <v-alert text color="blue darken-4" outlined height="120">
              <v-row>
                <v-col class="mt-3">
                  <h1 class="text-center">{{ widget.unit_count }}</h1>
                  <p class="text-center">سەرجەم یەکەکان</p>
                </v-col>
                <v-col class="text-left"><v-icon size="100" color="blue darken-4">mdi-sitemap</v-icon></v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" md="3" lg="3" sm="6">
            <v-alert text color="success accent-2" outlined height="120">
              <v-row>
                <v-col class="mt-3">
                  <h1 class="text-center">{{ Number(total).toLocaleString() }}</h1>
                  <p class="text-center"> پارەی ناو مەخزەن</p>
                </v-col>
                <v-col class="text-left"><v-icon size="100" color="success accent-2">mdi-currency-usd</v-icon></v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn class=" blue--text mb-2 accent-2" outlined
              @click="download()"><v-icon>mdi-download</v-icon>دابەزاندن</v-btn>
            <v-card>
              <v-data-table :headers="headers" :items="itemReport" :loading="loading" :search="table.search">
                <template v-slot:top>
                  <v-text-field @focus="$event.target.select()" v-model="table.search" label="گەڕان" clearable=""
                    class="mx-4"></v-text-field>
                </template>
                <template v-slot:[`item.qty_unit`]="{ item }">

                  {{ item.qty_unit ? item.qty_unit : 0 }}
                  <v-chip small class="primary">
                    {{ item.unit_name }}
                  </v-chip>
                </template>
                <template v-slot:[`item.qty_item`]="{ item }">
                  {{ item.qty_item ? item.qty_item : 0 }}
                </template>
                <template v-slot:[`item.total_amount`]="{ item }">
                  {{ '$' + item.total_amount }}
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'
export default {
  data() {
    return {
      loading: false,
      itemReport: [],
      table: {
        search: ''
      },
      widget: {
        item_count: 0,
        unit_count: 0,
        category_count: 0
      },
      headers: [
        { text: 'ناوی کاڵا', value: 'item_name', align: 'center' },
        { text: 'کۆدی کاڵا', value: 'item_code', align: 'center' },
        { text: 'بڕی ماوە بە یەکە', value: 'qty_unit', align: 'center' },
        { text: 'بڕی ماوە بە دانە', value: 'qty_item', align: 'center' },
        { text: 'بڕی ماوە بە پارە', value: 'total_amount', align: 'center' },
      ]
    }
  },
  methods: {
    async getItemReport() {
      try {
        this.loading = true
        const { data } = await this.axios.get('/report/inventory')
        this.itemReport = data
        this.loading = false
      } catch (error) {
        console.log(error);
      }
    },
    async getWidgetData() {
      try {
        const { data } = await this.axios.get('/report/topWidget')
        this.widget = data
      } catch (error) {
        console.log(error);
      }
    },
    async download() {
      window.open('/report/inventoryPrint', '_blank', 'width=595', 'height=842')

    }
  },
  created() {
    this.getItemReport()
    this.getWidgetData()
  },
  computed: {
    total() {
      let total = 0
      this.itemReport.forEach(item => { total = total + item.total_amount })
      return total
    }
  }
}
</script>

<style></style>